import router from '../router'

const env = process.env.VUE_APP_ENV || 'prod'
let host = ''

if (env === 'prod') {
    host = 'https://web.50bangzh.com'
} else {
    host = 'https://union2-50bang.st1.2345.cn'
}
const reportFn = function (IO, act) {
    let reportUrl = `${host}/web/ajax372?uId2=SPTNPQRLSX&r=${encodeURIComponent(location.href)}&IO=${IO}&act=${act}`
    let _dh = document.createElement("script");
    _dh.setAttribute("type", "text/javascript");
    _dh.setAttribute("src", reportUrl);
    document.getElementsByTagName("head")[0].appendChild(_dh);
}
window.reportFn = reportFn



// let reportUrl = ''
// if (env === 'prod') {
//     reportUrl = '//passport-plugin.hao184.com/statistics-report/v2.1.js'
// } else {
//     reportUrl = '//passport-plugin.st0.2345.cn/statistics-report/v2.js'
// }
// loadScript(reportUrl, function () {
//     // 文档： https://confluence.2345.cn/pages/viewpage.action?pageId=123249459
//     // 实例化上报对象,初始化一次即可
//     window.testReport = new StatisticsReport({
//         code: 'ajax372', // 对应统计表code，必填
//         defaultListener: ['click']
//     })

// })

// function loadScript(src, cb) {
//     let head = document.head || document.getElementsByTagName('head')[0];
//     let script = document.createElement('script');
//     cb = cb || function () { };
//     script.type = 'text/javascript';
//     script.src = src;

//     if (!('onload' in script)) {
//         script.onload = script.onreadystatechange = function () {

//             if (
//                 this.readyState &&
//                 (this.readyState === 'loaded' ||
//                     this.readyState === 'complete')
//             ) {
//                 this.onreadystatechange = null;
//                 cb(script);
//             }
//         };
//     } else {
//         script.onload = function () {
//             this.onload = null;
//             cb(script);
//         };
//     }

//     head.appendChild(script);
// }