import request from '@/utils/request'
import config from '@/config'
console.log('🚀 ~ file: integral.js:3 ~ integralApi:', config.integralApi)
export default {
    // 检查接口  账户数据是否已同步过，只能同步一次
    syncDataCheck: data => {
        return request({
            data,
            url: '/api/sync/check?platform=1',
            method: 'get',
            baseUrl: config.integralApi
        })
    },
    // 发送验证码接口
    syncSendSmsCode: data => {
        return request({
            data,
            url: '/api/sync/send?platform=1',
            method: 'post',
            baseUrl: config.integralApi

        })
    },

    // 验证验证码并同步数据接口
    syncData: data => {
        return request({
            data,
            url: '/api/sync/verify?platform=1',
            method: 'post',
            baseUrl: config.integralApi
        })
    },

}