import { createStore } from 'vuex'
import cache from '@ued2345/single-storage'
import config from '@/config/index'

// 引入缓存命名空间
cache.initNamespace(config.namespace)
const initData = {
  // 用户信息
  userInfo: {
    persistence: true, // 是否持久化，是否存入localStorage
    default: {}
  },
  isCheckLogin: {
    persistence: false,
    default: false
  },
  isLogined: {
    persistence: true,
    default: false
  },
  ticket: {
    persistence: true,
    default: ''
  },
  isCreating: {
    persistence: false,
    default: false
  },
  historyList: {
    persistence: false,
    default: []
  },
  creatingInfo: {
    persistence: false,
    default: {
      isloading: false,
      duration: 0,
      percent: 0,
      errorCode: null,

    }
  },
  isLogout: {
    persistence: false,
    default: false
  },

}

const state = {}

const mutations = {}

// 初始化state和mutation
for (const key in initData) {
  if (typeof initData[key].default === 'undefined') {
    throw new Error('[Store]initData中的默认值是不可以是undefined，这可能会导致一个取值错误')
  }
  if (initData[key].persistence) {
    const localVal = cache.getFromLoacl(key)
    const sessionVal = cache.getFromSession(key)
    state[key] = typeof localVal !== 'undefined'
      ? localVal
      : typeof sessionVal !== 'undefined'
        ? sessionVal
        : initData[key].default
    mutations[key] = (storeState, obj) => {
      storeState[key] = obj
      cache.saveToLocal(key, obj)
      cache.saveToSession(key, obj)
    }
  } else {
    // const sessionVal = cache.getFromSession(key)
    // state[key] = typeof sessionVal !== 'undefined'
    //   ? sessionVal
    //   : initData[key].default

    state[key] = initData[key].default
    mutations[key] = (storeState, obj) => {
      storeState[key] = obj
      cache.saveToSession(key, obj)
    }
  }
}
export default createStore({
  state,
  mutations,
  actions: {
  },
  modules: {
  }
})
