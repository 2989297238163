/**
 * 全局配置：env/mock
 * 地址管理：接口base地址、MockApi地址、SentryApi地址、
 * 获取系统环境，默认dev，开发环境
 * 根据domain动态获取当前环境
 */
const env = process.env.VUE_APP_ENV || 'prod'
const match = (document.domain.match(/([^.*]+).2345.cn/) || ['', 'st0'])[1]
console.log('当前环境:' + env)
const EnvConfig = {
  dev: { // 开发环境
    baseApi: `//aidraw-api.${match}.2345.cn`,
    // baseApi: `//login.${match}.2345.cn`,
    integralApi: `//arti.st0.2345.cn`
  },
  st0: {
    baseApi: `//aidraw-api.${match}.2345.cn`,
    // baseApi: `//login.${match}.2345.cn`,
    integralApi: `//arti.${match}.2345.cn`

  },
  st1: {
    baseApi: `//aidraw-api.${match}.2345.cn`,
    // baseApi: `//login.${match}.2345.cn`,
    integralApi: `//arti.${match}.2345.cn`

  },

  pre: { // pre
    baseApi: '//aigc-api.k986.com',
    integralApi: `//arti.${match}.2345.cn`

  },
  prod: { // 生产环境
    baseApi: '//aigc-api.k986.com',
    integralApi: `//arti.k986.com`
  }
}
export default {
  env, // 当前环境dev|test|prod
  routePass: false, // 全局设置所有路由免验证，仅在开发环境下生效
  mock: false, // 是否开启全局mock,即使开启，在生产环境也不会生效
  namespace: 'AIGC-2345', // 项目localStorage\sessionStorage命名空间
  ...EnvConfig[env]
}
