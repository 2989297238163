import request from '@/utils/request'
import config from '@/config'
console.log('🚀 ~ file: integral.js:3 ~ integralApi:', config.integralApi)
export default {
    // 商品信息接口
    getGoodsList: data => {
        return request({
            data,
            url: '/api/order/goods',
            method: 'get',
            baseUrl: config.integralApi
        })
    },
    // 订单提交
    createOrder: data => {
        return request({
            data,
            url: '/api/order/submit?platform=1',
            method: 'post',
            baseUrl: config.integralApi

        })
    },
    // 支付结果查询
    getOrderResult: data => {
        return request({
            data,
            url: '/api/order/result',
            method: 'get',
            baseUrl: config.integralApi

        })
    },
    // 用户信息接口（登录接口）
    getUserInfo: data => {
        return request({
            data,
            url: '/api/user/info?platform=1',
            method: 'post',
            baseUrl: config.integralApi
        })
    },

}