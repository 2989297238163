import request from '@/utils/request'
export default {
  // 查询用户历史作画
  getTaskHistory: data => {
    return request({
      data,
      // url: '/v3/user/log/queryLog',
      url: '/api/task/v1/history',
      method: 'post',
    })
  },
  // 【任务】创建文生图任务
  createTask: data => {
    return request({
      data,
      url: '/api/task/v1/txt2img?platform=1',
      method: 'post',
    })
  },
  // 【任务】查询任务进度
  checkProgress: data => {
    return request({
      data,
      url: '/api/task/v1/progress',
      method: 'post',
    })
  },
  // 【模型】获取模型风格列表  主题
  getThemeList: data => {
    return request({
      data,
      url: '/api/model/v1/modelPackage/list',
      method: 'get',
    })
  },
  // 【模型】获取首页混合模型配置信息(sd+mj)
  getMixModelInfo: data => {
    return request({
      data,
      url: '/api/model/v1/mixModelInfo',
      method: 'get',
    })
  },
}
