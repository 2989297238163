/**
 * ajax请求封装
 * 1、调用客户端的加解密方法
 * 2、统一处理请求头，将post转成form-urlencoded的形式
 * 3、ajax请求默认设置，如过期时间
 * 4、统一处理响应信息
 */
import axios from 'axios'
import config from '@/config/index'
import store from '@/store'
import 'element-plus/es/components/message/style/css'
import { ElMessage } from 'element-plus'
/**
 *  自定义配置新建一个 axios 实例
 * @type {AxiosInstance}
 */
const service = axios.create({
  timeout: 30000, // 请求超时时间
  baseURL: config.baseApi
})
// 请求拦截器
service.interceptors.request.use(config => {
  const headers = config.headers
  // TODO 这里需要针对项目修改ticket传递方式
  if (config.baseUrl) config.baseURL = config.baseUrl
  if (config.isDownload) config.responseType = 'blob'
  if (!headers.ticket) headers.ticket = store.state.ticket
  // if (!headers.authToken) headers.authToken = store.state.token

  return config
})
// 响应拦截器
service.interceptors.response.use(response => {
  // 获取返回结构
  // TODO code, data, msg 是公司最新的响应体结构
  // TODO success， errorCode， errorMessage是原中台权限中心的响应体结构
  const { code, data, msg, message, success, errorCode, errorMessage } = response.data
  // code为0，直接把结果返回回去，这样前端代码就不用在获取一次data
  if (response.config.responseType === 'blob') {
    // 文件流，下载文件
    // return fileReaderhandle(response)
  } else if (success === true || code === 0 || code === 200 || code === '200') {
    return response.config.getTotal ? response.data : data
  } else {
    // TODO 这是适配中台权限中心的errorCode
    if (errorCode === '105') {
      console.log('🚀 ~ file: request.js:52 ~ store.state.ticket:', store.state.ticket)
    }
    // ElMessage.error(errorMessage || msg || message || '网络请求异常，请稍后重试!')
    return Promise.reject(response.data || '网络请求异常，请稍后重试!')
  }
}, (err) => {
  console.log('🚀 ~ file: request.js:55 ~ err:', err)
  ElMessage.error('网络请求异常，请稍后重试!')
  return Promise.reject(err)
})

// 处理导出的文件流
function fileReaderhandle(res) {
  const data = res.data
  const fileReader = new FileReader()
  fileReader.onload = function () {
    try {
      const jsonData = JSON.parse(this.result) // 说明是普通对象数据，后台转换失败
      if (jsonData.code) {
        // showToast.error({
        //   title: '文件导出失败',
        //   message: jsonData.msg || '文件导出失败!'
        // })
      }
    } catch (err) { // 解析成对象失败，说明是正常的文件流
      // downloadFile(res)
    }
  }
  fileReader.readAsText(data)
}

// 下载文件
function downloadFile(res) {
  const url = window.URL.createObjectURL(new Blob([res.data]))
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  console.log(res)
  const contentDisposition = res.headers['content-disposition']
  let fileName = 'unknown'
  if (contentDisposition) {
    fileName = window.decodeURI(res.headers['content-disposition'].split('=')[1])
  }
  link.setAttribute('download', fileName)

  document.body.appendChild(link)
  link.click()
}
/**
 * 核心函数，可通过它处理一切请求数据，并做横向扩展
 * @param {url} 请求地址
 * @param {params} 请求参数
 * @param {options} 请求配置，针对当前本次请求；
 * @param mock 本次是否请求mock而非线上
 */
function request(options) {
  // options.method默认为get
  options.method = options.method || 'get'
  // get请求使用params字段
  if (options.method.toLowerCase() === 'get') {
    (!options.params) && (options.params = options.data)
  }
  // 局部接口mock
  if (config.env === 'prod') {
    service.defaults.baseURL = config.baseApi
  } else {
    let isMock = false
    if (Object.prototype.hasOwnProperty.call(options, 'mock')) {
      isMock = !!options.mock
    } else {
      isMock = !!config.mock
    }
    
    service.defaults.baseURL = isMock ? config.mockApi : config.baseApi
  }
  // 错误提示文案
  return service(options)
}

// 适配this.$request.get()调用
['get', 'post', 'patch', 'delete', 'put'].forEach(item => {
  request[item] = (url, data, options) => {
    return request({
      url,
      data,
      method: item,
      ...options
    })
  }
})
export default request
